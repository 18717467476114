/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

   /////////Navigation on click;
   $("#sidemenu-mobile").on("click", "a", function (event) {
    event.preventDefault();
    $(".hamburger").trigger("click");
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top - 72,
    }, 1600);
  });



})(jQuery); // Fully reference jQuery after this point.

(function($){
  $('.dropdown-menu').click(function (event) {
    event.stopPropagation();
  });
  $('.dropdown-menu').on("click", ".dropdown-toggle", function () {
    $('ul.dropdown-menu').not($(this).parent().parent()).not($(this).next("ul.dropdown-menu")).removeClass("show");
    $(this).next("ul.dropdown-menu").toggleClass("show");
  });

  var menuneg = '-100%';
  var toggler = '.hamburger';
  var slidewidth = '80%';
  var area = '.header-container';

  $(".lt-header").on("click", toggler, function () {
    var selected = $(this).hasClass('active');
    $('#sidemenu-mobile').stop().animate({
      left: selected ? menuneg : '0px',
    }, 500);

    if (!selected) {
      $(area).addClass('slide-active');
    }
    $(area).stop().animate({
      left: selected ? '0px' : slidewidth,
    }, 500, function () {
      if (selected) {
        $('body').css({
          'overflow-y': 'unset',
        });
        $(area).removeClass('slide-active');
      } else {
        $('body').css({
          'overflow-y': 'hidden',
        });
      }
    });
    $(this).toggleClass('active', !selected);
    $('#sidemenu-mobile').toggleClass('slide-active', !selected);
  });

  $(window).on("resize", function () {
    if ($(window).width() > 767 && $('.hamburger').is(':hidden')) {
      $(area).stop().animate({
        left: '0px',
      }, 500, function () {
        $(area).removeClass('slide-active');
      });
    }
  });
})(jQuery);

(function($){
  $(document).scroll(function () {
    var $nav = $(".fixed-top");
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
  });
})(jQuery);
